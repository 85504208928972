// 构建在线车辆树
export function buildOnlineTreeData(items, onlineList) {
  for (let k = items.length - 1; k >= 0; k--) {
    const item = items[k]
    if (!item.parent) {
      item.parent = items
    }
    if (!item.children) {
      if (onlineList.indexOf(item.id) < 0) {
        let parent = null
        if (!item.parent) {
          parent = items
          parent.forEach((t, idx) => {
            if (t.id === item.id) {
              parent.splice(idx, 1)
            }
          })
        } else {
          parent = item.parent.children ? item.parent.children : item.parent
          if (parent) {
            parent.forEach((t, idx) => {
              if (t.id === item.id) {
                parent.splice(idx, 1)
              }
            })
            // 递归删除父级
            if (parent.length === 0) {
              deleteTreeParentNode(item.parent)
            }
          }
        }
      } else {
        item.online = 1
      }
    } else {
      item.children.forEach((p) => (p.parent = item))
      buildOnlineTreeData(item.children, onlineList)
    }
  }
}

// 删除树父节点
function deleteTreeParentNode(item) {
  if (item.children.length === 0) {
    const parent = item.parent.children ? item.parent.children : item.parent
    parent.forEach((t, idx) => {
      if (t.id === item.id) {
        parent.splice(idx, 1)
      }
    })
    if (parent.length === 0) {
      // 如果是根一级
      if (Array.isArray(item.parent) && item.parent.length === 0) {
      } else if (item.parent) {
        deleteTreeParentNode(item.parent)
      }
    }
  }
}

// 设置车辆在线标识
export function setOnlineFlag(items, map) {
  items.forEach((p) => {
    if (p.id.indexOf('t-1') >= 0) {
      if (map.has(p.id)) {
        p.online = map.get(p.id)
      }
    }
    if (p.children) {
      setOnlineFlag(p.children, map)
    }
  })
}
