import get from 'lodash/get'
import { GetMapAddress } from '../monitorComponents/utils/mapUtil'
import { buildOnlineTreeData, setOnlineFlag } from '@/views/monitor/utils/treeHelper'
import { getOnlineList } from '@/api/jt808/deviceStatus'
export default {
  data() {
    return {
      arrowUrl: require('@/assets/images/arrow.png')
    }
  },

  methods: {
    // 设置箭头方向
    setHeadingArrow(obj, e) {
      // console.log('setHeadingArrow')
      if (!obj) {
        return
      }
      setTimeout(() => {
        const headingValue = get(obj, 'location.heading', 0)
        if (e) {
          const rotateStr = 'rotate(' + (headingValue - 90) + 'deg)'
          e.style.msTransform = rotateStr
          e.style.webkitTransform = rotateStr
          e.style.MozTransform = rotateStr
          e.style.OTransform = rotateStr
          e.style.transform = rotateStr
        }
      }, 100)
    },
    setArrow(e, val) {
      // console.log('setArrow')
      setTimeout(() => {
        if (e) {
          const rotateStr = 'rotate(' + (val - 90) + 'deg)'
          e.style.msTransform = rotateStr
          e.style.webkitTransform = rotateStr
          e.style.MozTransform = rotateStr
          e.style.OTransform = rotateStr
          e.style.transform = rotateStr
        }
      }, 100)
    },
    setSpanValue(id, value) {
      const el = document.getElementById(id)
      if (el) {
        el.innerText = value
      }
    },
    setMapAddress() {
      console.log('setMapAddress')
      const { mapGeo, BMap } = this.mapObj
      let { lng, lat } = this.objectInfo
      if (this.lng) {
        lng = this.lng
      }
      if (this.lat) {
        lat = this.lat
      }
      GetMapAddress(mapGeo, BMap, lng, lat, (address) => {
        this.mapAddress = address
        this.$emit('onResetObjectInfoWindow')
      })
    },
    // 设置树的在线状态
    initTreeData(data, online) {
      if (!data) {
        return
      }
      data.forEach((p) => {
        if (p.id.indexOf('t-1') >= 0) {
          p.online = online
          this.treeObjectOnlineMap.set(p.id, online)
        }
        if (p.children) {
          this.initTreeData(p.children, online)
        }
      })
    },
    doUpdateTreeData(data) {
      console.log('doUpdateTreeData')
      let isUpdateTree = false
      data.forEach((p) => {
        const id = 't-1-' + p.deviceId
        const online = p.online ? 1 : 0
        const lastOnline = this.treeObjectOnlineMap.get(id)
        if (online !== lastOnline) {
          isUpdateTree = true
          this.treeObjectOnlineMap.set(id, online)
        }
      })
      if (isUpdateTree) {
        // 更新车辆树
        const treeData = [...this.treeData]
        // 设置树中车辆的在线标识
        setOnlineFlag(treeData, this.treeObjectOnlineMap)
        this.treeData = treeData
      }
    },
    // 过滤数据
    handleFilterTreeData(isAll, deviceType) {
      this.isAll = isAll
      const data = JSON.parse(JSON.stringify(this.allTreeData))
      if (this.isAll) {
        // 如果显示全部车辆
        // 更新车辆树
        setOnlineFlag(data, this.treeObjectOnlineMap)
        this.treeData = [...data]
      } else {
        const params = {
          deviceType: deviceType
        }
        getOnlineList(params).then((res) => {
          console.log('getOnlineList', res)
          this.onlineList = res.data.map((p) => 't-1-' + p.deviceId)
          // 如果只显示在线的车辆
          buildOnlineTreeData(data, this.onlineList)
          this.treeObjectOnlineMap.clear()
          this.onlineList.forEach((p) => {
            this.treeObjectOnlineMap.set(p, 1)
          })
          this.treeData = data
        })
      }
    }
  }
}
