/*
 * @Auth: linjituan
 * @Date: 2021-11-11 10:32:55
 * @LastEditors: linjituan linjituan@revolution.com
 * @LastEditTime: 2024-04-02 11:04:23
 */
import request from '@/utils/request'
const baseURL = '/jt-api'

// 查询设备状态列表
export function listDeviceStatus(query) {
  return request({
    url: '/deviceStatus/list',
    method: 'get',
    baseURL,
    params: query
  })
}

// 查询设备状态详细
export function getDeviceStatus(id) {
  return request({
    url: '/deviceStatus/' + id,
    baseURL,
    method: 'get'
  })
}

// 新增设备状态
export function addDeviceStatus(data) {
  return request({
    url: '/deviceStatus',
    method: 'post',
    baseURL,
    data: data
  })
}

// 修改设备状态
export function updateDeviceStatus(data) {
  return request({
    url: '/deviceStatus',
    method: 'put',
    baseURL,
    data: data
  })
}

// 删除设备状态
export function delDeviceStatus(id) {
  return request({
    url: '/deviceStatus/' + id,
    baseURL,
    method: 'delete'
  })
}

// 导出设备状态
export function exportDeviceStatus(query) {
  return request({
    url: '/deviceStatus/export',
    method: 'get',
    baseURL,
    params: query
  })
}

// 根据若干设备id查询各设备状态
export function queryDeviceStatus(data) {
  return request({
    url: '/deviceStatus/query',
    method: 'post',
    baseURL,
    data: data
  })
}

// 根据若干设备id查询各设备状态
export function queryCleanerDeviceStatus(data) {
  return request({
    url: '/deviceStatus/query/99',
    method: 'post',
    baseURL,
    data: data
  })
}
// 查询车辆设备状态
export function queryVehicleDeviceStatus(data) {
  return request({
    url: '/deviceStatus/query/1',
    method: 'post',
    baseURL,
    data: data
  })
}

// 获取在线设备列表
export function getOnlineList(query) {
  return request({
    url: '/deviceStatus/onlineList',
    method: 'get',
    baseURL,
    params: query
  })
}

// Dashboard大屏数据查询
export function queryDashboard(query) {
  return request({
    url: '/deviceStatus/queryDashboard',
    method: 'get',
    baseURL,
    params: query
  })
}
