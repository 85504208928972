<template>
  <div>
    <div class="container">
      <div class="row">
        <span>在岗人数：</span>
        <span id="realOnDutyCount" />
      </div>
      <div class="row">
        <span>应在岗人数：</span>
        <span id="mustOnDutyCount" />
      </div>
    </div>
  </div>
</template>
<script>
import MonitorBase from '@/views/monitor/monitorBase'
export default {
  name: 'MapCleanerInfoWindow',
  mixins: [MonitorBase],
  props: {},
  watch: {},
  data() {
    return {}
  },
  mounted() {},
  methods: {
    setFormData(form) {
      // console.log('setFormData')
      if (!form) {
        return
      }
      this.setSpanValue('realOnDutyCount', form.realOnDutyCount)
      this.setSpanValue('mustOnDutyCount', form.mustOnDutyCount)
    }
  }
}
</script>
<style lang="less" scoped>
.container {
  display: flex;
  flex-direction: column;
  padding-left: 5px;
  padding-right: 5px;
  padding-bottom: 13px;
  .row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0;
    span:first-child {
      min-width: 70px;
    }
  }
}
</style>

<style lang="less">
.cleaner-object-info {
  justify-content: space-between;
  // height: 40px;
  span:first-child {
    padding-right: 3px;
  }
  .ant-btn {
    height: 22px !important;
    border-radius: 1px !important;
    padding-left: 5px;
    padding-right: 5px;
  }
}
</style>
